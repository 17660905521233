module.exports = {
  "prof-101": {
    "identifierType": "UTK",
    "maxAge": 86400000,
    "parameters": {
      "group": [
        "control",
        "variant"
      ]
    }
  }
};