'use es6';

import { createClient } from 'laboratory-lib';
import { getUtk } from 'usage-tracker-core/common/defaultTrackers';
import experiments from '../../experiments.yaml';
export default createClient({
  quickFetchLabel: 'public-experiment-treatments',
  experiments,
  timeout: 8000,
  identifiers: {
    utk: getUtk()
  }
});